import React from "react";
import facebook from "../../assets/images/facebook.png";
import insta from "../../assets/images/insta.png";
import tiktok from "../../assets/images/tiktok.png";
import sec1homeimg from "../../assets/images/sec1homeimg.png";
import { Link } from "react-router-dom";
import logo from "../../assets/images/Logo.png";

import location from "../../assets/images/location.png";
import call from "../../assets/images/call.png";
import email from "../../assets/images/email.png";
function Footer() {
  return (
    <div>
      <footer>
        <div className="container-1600">
          <div className="row">
            <div className="col-lg-2 get-touch m-auto">
              <h2>Get in touch</h2>
              {/* 
              <span className="links-social">
                {" "}
                <Link to="tel:0431558274">
                  {" "}
                  <img src={call} /> 0431 558 274
                </Link>
              </span> */}
              <span className="links-social">
                {" "}
                <Link to="mailto:evanbekiaris@gmail.com">
                  {" "}
                  <img src={email} /> info@newworldmacedonia.com.au{" "}
                </Link>
              </span>
              <span className="links-social">
                {" "}
                <Link
                  to="https://www.google.com/maps/place/Coronet+Bay+VIC+3984,+Australia/@-38.4347828,145.4329465,15z/data=!3m1!4b1!4m6!3m5!1s0x6ad5f830318a87d1:0x40579a430a06dc0!8m2!3d-38.4346491!4d145.4410083!16s%2Fm%2F0r8q5rd?entry=ttu"
                  target="blank"
                >
                  {" "}
                  <img src={location} /> Coronet Bay, VIC, 3984{" "}
                </Link>
              </span>
            </div>
            <div className="col-lg-8 m-auto ">
              <img src={logo} />
              <p>The World's First Cyber Nation</p>
            </div>
            <div className="col-lg-2 social m-auto">
              <h2>Quick Links</h2>
              <ul className="mt-4">
                <li>
                  {" "}
                  <Link to="/about">About Us</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/contact">Contact Us</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/become">Become a Citizen</Link>{" "}
                </li>
              </ul>
              <div className="nonekar">
                <h2>Follow us</h2>
                <ul className="icons-soc">
                  <li>
                    {" "}
                    <Link to="https://www.facebook.com/" target="Blank">
                      {" "}
                      <img src={facebook} />
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="https://www.instagram.com/" target="Blank">
                      {" "}
                      <img src={insta} />
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="https://www.tiktok.com/" target="Blank">
                      {" "}
                      <img src={tiktok} />
                    </Link>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copy-right">
        <div className="container-1600">
          <div className="copy-right-inner">
            <p>© 2023 NewWorldMacedonia. | All Rights Reserved</p>
          </div>
          <div className="copy-right-inner">
            <p>
              <Link to="https://webdesignjack.com/" target="blank">
                Who Designed This Website?
              </Link>
            </p>
          </div>
          <div className="copy-right-inner d-flex">
            <div className="copy-right-inner-right">
              <Link to="/term">Terms &amp; Conditions</Link>
            </div>
            {/* <div className="copy-right-inner-right">
              <Link className="brdr-anchor" to="/privacy-policy">
                {" "}
                <span>| </span> Privacy Policy{" "}
              </Link>
            </div>
            <div className="copy-right-inner-right">
              <Link to="/cookie-policy  ">
                {" "}
                <span>| </span> Cookie Policy
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
