import React from "react";
import SidebarAdmin from "../Component/SidebarAdmin";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
function AdminDashboard() {
  return (
    <div>
      <section className="Dashboard-citezen">
        <SidebarAdmin />

        <div className="Dashboard-righ-side">
          <div className="dash-title">
            <h1> Admin Dashboard </h1>
          </div>

          <div className="dash-page-con ">
            <div className="enter-form admin-dv">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    Hi <span> Admin! </span>
                  </h3>
                  <h4> Welcome to </h4> <h1> New World Macedonia </h1>
                  <h4> Admin Dashboard </h4>
                </div>
                <div className="col-lg-4">
                  <img src={Logo} />
                </div>
              </div>
            </div>

            <div className="row comments " style={{ marginTop: "25px" }}>
              <div className="col-lg-6">
                <div class="row notificationpg" style={{ marginTop: "25px" }}>
                  <div class="col-lg-2"></div>
                  <div class="col-lg-8">
                    <h2>has been registered.</h2>
                    <span>hours ago.</span>
                    <span className=""></span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 m-auto total">
                <div
                  class="row notificationpg"
                  style={{ marginLeft: "auto", marginBottom: "30px" }}
                >
                  <div class="col-lg-2">
                    <img src={Logo} />
                  </div>
                  <div class="col-lg-8">
                    <h2>Total Citizens</h2>
                    <p>User</p>
                  </div>
                </div>
                <div
                  class="row notificationpg"
                  style={{
                    marginLeft: "auto",
                    position: "Reletive",
                    top: "30px",
                  }}
                >
                  <div class="col-lg-2">
                    <img src={Logo} />
                  </div>
                  <div class="col-lg-8">
                    <h2>Citizens Registered</h2>
                    <p>Data Cust</p>

                    <select name="date" id="date">
                      <option disabled selected>
                        Select Time
                      </option>
                      <option value="3">This Date</option>
                      <option value="1">This Week</option>
                      <option value="2">This Month</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="banner-btn" style={{ marginTop: "45px" }}>
              <Link> Mark All As Read </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminDashboard;
