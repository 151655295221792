import React from "react";
import videoban from "../../assets/video/HeroBanner.mp4";

import { Link } from "react-router-dom";
import bannerleftsideimg from "../../assets/images/banner-left-side-img.png";
import bannerrightsideimg from "../../assets/images/banner-right-side-img.png";

import emaillogin from "../../assets/images/emaillogin.svg";
import lock from "../../assets/images/lock.svg";
import Logo from "../../assets/images/Logo.png";
import backtoweb from "../../assets/images/backtoweb.svg";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-pg">
      <div className="bg-video-wrap">
        <video src={videoban} loop muted autoPlay></video>
        <div className="banner-content">
          <span className="backtoweb">
            {" "}
            <Link to="/">
              {" "}
              <img src={backtoweb} />
            </Link>
          </span>
          <span className="sp-ban-1">
            {" "}
            <img src={bannerleftsideimg} />
          </span>
          <span className="sp-ban-2">
            {" "}
            <img src={bannerrightsideimg} />
          </span>
          <div className="banner-login">
            <span className="bg-login-logo">
              <img src={Logo} alt="Logo" />
            </span>
            <div className="row">
              <div className="col-lg-12">
                <h1>Login</h1>

                <p className="welcom">Welcome! Please enter your details</p>
                <form className="input-login">
                  <div className="field-in">
                    <input type="email" id="" placeholder="Email Address" />
                    <img src={emaillogin} alt="Email Icon" />
                  </div>
                  <div className="field-in">
                    <input
                      type={showPassword ? "text" : "password"}
                      id=""
                      placeholder="Password"
                    />

                    <div className="eye-hide">
                      {showPassword ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={togglePasswordVisibility}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          onClick={togglePasswordVisibility}
                        />
                      )}
                    </div>

                    <img
                      src={lock}
                      alt="Lock Icon"
                      className="password-toggle-icon"
                    />
                  </div>
                  <div className="forgerting">
                    <span>
                      <input type="checkbox" id="vehicle1" />
                      <p>Remember me</p>
                    </span>
                    <span>
                      <p>
                        {" "}
                        <Link to="/Forget"> Forgot Password?</Link>
                      </p>
                    </span>
                  </div>

                  <div className="banner-btn">
                    <Link>Login</Link>
                    <Link to="/Register">Register</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
