import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import videoban from "../../assets/video/HeroBanner.mp4";

import { Link } from "react-router-dom";
import bannerleftsideimg from "../../assets/images/banner-left-side-img.png";
import bannerrightsideimg from "../../assets/images/banner-right-side-img.png";

import worldban from "../../assets/images/world-ban.png";
import sec1homeimg from "../../assets/images/sec1homeimg.png";
import Footer from "../../assets/Component/Footer";
import Header from "../../assets/Component/Header";
import martin from "../../assets/images/martin.png";

import how1 from "../../assets/images/how1.png";
import how2 from "../../assets/images/how2.png";
import how3 from "../../assets/images/how3.png";
import philip from "../../assets/images/philip2.png";
import Alex from "../../assets/images/Alex.png";
import contactsec1 from "../../assets/images/contact-sec1.png";

import lennon from "../../assets/images/lennon.png";

// importing aos

function About() {
  const [showMore, setShowMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleContent = () => {
    setShowMore(!showMore);
  };
  useEffect(() => {
    AOS.init({
      // AOS configuration options
    });

    // Refresh AOS when component mounts
    AOS.refresh();

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Refresh AOS on scroll to trigger animations
    AOS.refresh();
  };

  return (
    <div>
      <Header />

      <div className="bg-video-wrap">
        <video src={videoban} loop muted autoPlay></video>
        <div className="banner-content">
          <span className="sp-ban-1">
            {" "}
            <img src={bannerleftsideimg} />
          </span>
          <span className="sp-ban-2">
            {" "}
            <img src={bannerrightsideimg} />
          </span>
          <div className="banner-title">
            <div className="banner-contact">
              <i class="fa-solid fa-download fa-spin fa-spin-reverse">
                {" "}
                <span className="sp-ban-world">
                  {" "}
                  <img
                    src={worldban}
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  />
                </span>
              </i>
              <h1 data-aos="zoom-in" data-aos-duration="2000">
                About{" "}
              </h1>
              <h2 data-aos="zoom-in" data-aos-duration="2000">
                New World Macedonia
              </h2>
              <p data-aos="zoom-in" data-aos-duration="2000">
                The world's first Cyber Nation
              </p>

              {/* <div className="banner-btn">
              <Link>Read More</Link>
              <Link>Become a Citizen</Link>
            </div> */}
            </div>
          </div>
        </div>
      </div>

      <section className="sec-1 ">
        <span className="sp-ban-1">
          {" "}
          <img src={bannerleftsideimg} />
        </span>
        <span className="sp-ban-2">
          {" "}
          <img src={bannerrightsideimg} />
        </span>
        <div className="container-1340">
          <div className="row">
            <div className="col-lg-1"> </div>
            <div className="col-lg-6">
              <span className="ALex">
                {" "}
                <img
                  src={Alex}
                  data-aos="zoom-in"
                  data-aos-duration="2500"
                  data-aos-offset="200"
                />
              </span>
              <div
                className="aobut-content"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h2>The world's first Cyber Nation </h2>
                <p>
                  We are creating world history for you and your family history,
                  but also we are helping to make a 2500 years old dream comes
                  true for Alexander The Great, ancient king of Macedonia.{" "}
                  <br />
                  <br />
                  Alexander dreamed of uniting the world, but sadly passed
                  before making his dream a reality.
                  <br />
                  <br />
                  The unity of mankind, One World.. One People.
                  <br />
                  <br />
                  Together we can make the dream come true.
                </p>
                <div class="banner-btn">
                  <Link to="/Become">Become a Citizen</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-5 aboutMacedonia">
              <img
                src={contactsec1}
                data-aos="zoom-in"
                data-aos-duration="2000"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="sec-2">
        <h1
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          {" "}
          Let's Know
          <br /> <span>Better History</span>
        </h1>
        <span className="sp-ban-1">
          {" "}
          <img src={bannerleftsideimg} />
        </span>
        <span className="sp-ban-2">
          {" "}
          <img src={bannerrightsideimg} />
        </span>
        <div className="container-1340">
          <div className="row let-know">
            <div className="col-lg-12 ">
              <div className="let-know ">
                <img
                  src={lennon}
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
                <img
                  src={martin}
                  data-aos="fade-up "
                  data-aos-duration="2000"
                  data-aos-anchor-placement="bottom-bottom"
                />
                <img
                  src={philip}
                  data-aos="fade-left"
                  data-aos-duration="2000"
                />
              </div>
              <p data-aos="fade-up" data-aos-duration="3000">
                Wanting to unite the world is not a new thing. John Lennon sang
                about it, Martin Luther King preached about it and certainly
                many people would love it to be true and possible. <br />
                Biographers and most scholars viewed Alexander the Great as a
                genius with a plan, a romantic figure pursuing his vision of a
                united world. His dream was at times characterized as a
                benevolent interest in the brotherhood of man.
                <br />
                Today you can help to make it happen. We need to send the world
                a message of peace, hope and harmony. This is a call for the
                dreamers of the world to show “Dreams can really come true”.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-2 bp-bt">
        <h1
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          How to
          <br /> <span>Become a Citizen</span>
        </h1>
        <span className="sp-ban-1">
          {" "}
          <img src={bannerleftsideimg} />
        </span>
        <span className="sp-ban-2">
          {" "}
          <img src={bannerrightsideimg} />
        </span>
        <div className="container-1050">
          <div className="row">
            <div
              className="col-lg-5 pb-5 become3"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src={how1} />
            </div>
            <div className="col-lg-7 pb-5">
              <span className="ALex">
                {" "}
                <img
                  src={Alex}
                  data-aos="zoom-in"
                  data-aos-duration="2500"
                  data-aos-offset="200"
                />
              </span>
              <div
                className="howto-content"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2>Register on website</h2>
                <p>
                  Citizenship to{" "}
                  <span style={{ color: "white", fontWeight: "600" }}>
                    {" "}
                    New World Macedonia{" "}
                  </span>
                  is open equally to all humans on planet Earth and will not be
                  restrictive in any form against any applicant ir-respective of
                  nationality, age, race, colour, gender identity, religious
                  beliefs. We have no borders, no nationalities, no politicians,
                  no passports, no wars and just want the world to peacefully
                  co-exist.
                </p>
              </div>
            </div>

            <div className="col-lg-7 pb-5 pb-5">
              <span className="ALex">
                {" "}
                <img
                  src={Alex}
                  data-aos="zoom-in"
                  data-aos-duration="2500"
                  data-aos-offset="200"
                />
              </span>
              <div
                className="howto-content"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2>Fill Citizenship Form</h2>
                <p>
                  Click on the Fill Citizenship Form Button Provide your details
                  follow the prompts and make your payment to receive your very
                  own world history First Cyber Nation citizenship certificate
                  from{" "}
                  <span style={{ color: "white", fontWeight: "600" }}>
                    {" "}
                    New World Macedonia .
                  </span>
                </p>
                <div
                  class="banner-btn"
                  style={{ justifyContent: "start", marginTop: "30px" }}
                >
                  <Link to="/Become">Become a Citizen</Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 pb-5 become3"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src={how2} />
            </div>

            <div
              className="col-lg-5 become3"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src={how3} />
            </div>
            <div
              className="col-lg-7 "
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <span className="ALex">
                {" "}
                <img
                  src={Alex}
                  data-aos="zoom-in"
                  data-aos-duration="2500"
                  data-aos-offset="200"
                />
              </span>
              <div className="howto-content">
                <h2>Get Certificate</h2>
                <p>
                  Introductory{" "}
                  <span style={{ color: "white", fontWeight: "600" }}>
                    {" "}
                    New World Macedonia{" "}
                  </span>{" "}
                  Citizenship Certificates issued until 31st July 2024 will be
                  treated as foundation members of the world’s first Cyber
                  Nation and may qualify as recognition in an attempt for a
                  world record recognition from the Guinness Book of World
                  Records.{" "}
                </p>
                <div
                  class="banner-btn"
                  style={{ justifyContent: "start", marginTop: "30px" }}
                >
                  <Link to="/Become">Become a Citizen</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default About;
