import React from "react";
import logo from "../../assets/images/Logo.png";
export default function Loader() {
  return (
    <div>
     
      <div className="pro-br">
      <img src={logo} className="afdfsd pb-3" />
        <div className="progress-bar ">
          <div className="progress-fill"></div>
        </div>
      </div>
    </div>
  );
}
