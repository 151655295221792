import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import videoban from "../../assets/video/HeroBanner.mp4";
import { Link } from "react-router-dom";
import bannerleftsideimg from "../../assets/images/banner-left-side-img.png";
import bannerrightsideimg from "../../assets/images/banner-right-side-img.png";
import worldban from "../../assets/images/world-ban.png";
import Header from "../../assets/Component/Header";
import Footer from "../../assets/Component/Footer";

function Become() {
  const [showMore, setShowMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleContent = () => {
    setShowMore(!showMore);
  };
  useEffect(() => {
    AOS.init({
      // AOS configuration options
    });

    // Refresh AOS when component mounts
    AOS.refresh();

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Refresh AOS on scroll to trigger animations
    AOS.refresh();
  };

  return (
    <div>
      <Header />

      <div className="bg-video-wrap">
        <video src={videoban} loop muted autoPlay></video>
        <div className="banner-content">
          <span className="sp-ban-1">
            {" "}
            <img src={bannerleftsideimg} />
          </span>
          <span className="sp-ban-2">
            {" "}
            <img src={bannerrightsideimg} />
          </span>
          <div className="banner-title">
            <div className="banner-contact">
              <span className="sp-ban-world">
                {" "}
                <img
                  src={worldban}
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                />
              </span>
              <h1 data-aos="zoom-in" data-aos-duration="2000">
                Become a Citizen
              </h1>
              <h2 data-aos="zoom-in" data-aos-duration="2000">
                Of New World Macedonia
              </h2>
              <p data-aos="zoom-in" data-aos-duration="2000">
                The world's first Cyber Nation
              </p>

              <div
                className="banner-btn"
                style={{ justifyContent: "start" }}
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <Link to="/Register">Register Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Become;
