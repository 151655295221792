import React from "react";
// import { Link } from 'react-router-dom';
import videoban from "../../assets/video/HeroBanner.mp4";

import { Link } from "react-router-dom";
import bannerleftsideimg from "../../assets/images/banner-left-side-img.png";
import bannerrightsideimg from "../../assets/images/banner-right-side-img.png";
import Logo from "../../assets/images/Logo.png";
import backtoweb from "../../assets/images/backtoweb.svg";

function Verification() {
  return (
    <div className="login-pg veriifcation-pg">
      <div className="bg-video-wrap">
        <video src={videoban} loop muted autoPlay></video>
        <div className="banner-content">
          <span className="backtoweb">
            {" "}
            <Link to="/">
              {" "}
              <img src={backtoweb} />
            </Link>
          </span>
          <span className="sp-ban-1">
            {" "}
            <img src={bannerleftsideimg} />
          </span>
          <span className="sp-ban-2">
            {" "}
            <img src={bannerrightsideimg} />
          </span>
          <div className="banner-login">
            <span className="bg-login-logo">
              <img src={Logo} alt="Logo" />
            </span>
            <div className="row">
              <div className="col-lg-12">
                <h1>Verification</h1>

                <form className="input-login">
                  <div className="field-in">
                    <input type="text" />
                    <input type="text" />
                    <input type="text" />
                    <input type="text" />
                  </div>

                  <div className="banner-btn">
                    <Link>Verify</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verification;
