import React from "react";
import SidebarAdmin from "../Component/SidebarAdmin";
import { Link } from "react-router-dom";

function CitizenAdmin() {
  return (
    <div>
      <section className="Dashboard-citezen">
        <SidebarAdmin />

        <div className="Dashboard-righ-side">
          <div className="dash-title">
            <h1> Admin Dashboard </h1>
          </div>

          <div className="dash-page-con ">
            <div className="enter-form ">
              <div className="row">
                <div className="col-lg-12">
                  <table class="simple_zebra">
                    <thead>
                      <tr>
                        <th>Joining Date</th>
                        <th>Citizen Name</th>
                        <th>Country</th>
                        <th>Certificate</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Data</td>
                        <td>
                          <Link
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Mark Allen
                          </Link>
                        </td>
                        <td>Ukrain</td>
                        <td>
                          <Link to="">View</Link>
                          <Link>Payment is pending</Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-body">
                <div className="enter-form">
                  <div className="row get-cert">
                    <div className="col-lg-6 set-img"></div>
                    <div className="col-lg-6 "></div>
                    <div className="col-lg-6">
                      <label>First Name</label>
                      <input type="text" id="" placeholder="Mark" />
                    </div>
                    <div className="col-lg-6">
                      <label> Last Name</label>
                      <input type="text" id="" placeholder="Allen" />
                    </div>
                    <div className="col-lg-6">
                      <label>Email</label>
                      <input
                        type="text"
                        id=""
                        placeholder="mark_allen@gmail.com"
                      />
                    </div>
                    <div className="col-lg-6">
                      <label>Phone Number</label>
                      <input type="number" id="" placeholder="1234 567 890" />
                    </div>
                    <div className="col-lg-12">
                      <label>Address</label>

                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        placeholder="Lorem ipsum dolor sir contour lorem ipsum dolor sir contour"
                      ></textarea>
                    </div>
                    <div className="col-lg-6">
                      <label>City</label>
                      <input type="text" id="" placeholder="Melbourne" />
                    </div>
                    <div className="col-lg-6">
                      <label>State</label>
                      <input type="text" id="" placeholder="New South Wales" />
                    </div>
                    <div className="col-lg-6">
                      <label>Country</label>
                      <input type="text" id="" placeholder="Australia" />
                    </div>
                    <div className="col-lg-6">
                      <div class="banner-btn">
                        <Link>Save Changes</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default CitizenAdmin;
