import React from "react";

import Sidebar from "../assets/Component/Sidebar";
import download from "../assets/images/download.svg";
import cert from "../assets/images/certi-image.jpg";
function Certificate() {
  return (
    <div>
      <section className="Dashboard-citezen">
        <Sidebar />

        <div className="Dashboard-righ-side">
          <div className="dash-title">
            <h1>Get Your Citizenship Certificate</h1>
          </div>

          <div className="dash-page-con certificate-page">
            <div className="certificate-sec">
              <div className="download-pdf">
                <img src={download} />
              </div>

              <div className="certicate-editable">
                <span className="certnme">Mark Allen</span>
                <span className="cetdate">12-1-2024</span>
                <img src={cert} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Certificate;
