import React from "react";
import SidebarAdmin from "../Component/SidebarAdmin";
import { Link } from "react-router-dom";

function PublishNotice() {
  return (
    <div>
      <section className="Dashboard-citezen">
        <SidebarAdmin />

        <div className="Dashboard-righ-side">
          <div className="dash-title">
            <h1> Admin Dashboard </h1>
          </div>

          <div className="dash-page-con ">
            <div className="enter-form admin-dv">
              <div className="row get-cert">
                <div className="col-lg-12">
                  <label>Subject</label>
                  <input type="text" id="" placeholder="Meetup" />
                </div>

                <div className="col-lg-12">
                  <label>Content</label>

                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Message"
                  ></textarea>
                </div>

                <div className="banner-btn">
                  <Link>Publish</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PublishNotice;
