// import { Link } from 'react-router-dom';
import videoban from "../../assets/video/HeroBanner.mp4";

import { Link } from "react-router-dom";
import bannerleftsideimg from "../../assets/images/banner-left-side-img.png";
import bannerrightsideimg from "../../assets/images/banner-right-side-img.png";

import worldban from "../../assets/images/world-ban.png";
import sec1homeimg from "../../assets/images/sec1homeimg.png";
import Footer from "../../assets/Component/Footer";
import Header from "../../assets/Component/Header";
import how1 from "../../assets/images/how1.png";
import how2 from "../../assets/images/how2.png";
import how3 from "../../assets/images/how3.png";
import maced from "../../assets/images/maced.png";
import Alex from "../../assets/images/Alex.png";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";

function Index() {
  const [showMore, setShowMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleContent = () => {
    setShowMore(!showMore);
  };
  useEffect(() => {
    AOS.init({
      // AOS configuration options
    });

    // Refresh AOS when component mounts
    AOS.refresh();

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Refresh AOS on scroll to trigger animations
    AOS.refresh();
  };

  return (
    <div>
      <Header />

      <div className="bg-video-wrap">
        <video src={videoban} loop muted autoPlay></video>
        <div className="banner-content">
          <span className="sp-ban-1">
            {" "}
            <img src={bannerleftsideimg} />
          </span>
          <span className="sp-ban-2">
            {" "}
            <img src={bannerrightsideimg} />
          </span>
          <div className="banner-title">
            <span
              className="sp-ban-world"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              {" "}
              <img src={worldban} />
            </span>
            <h1
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              Citizenship of New World <br /> Macedonia
            </h1>
            <p
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="2000"
            >
              The World's First Cyber Nation
            </p>

            <div
              className="banner-btn"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="2000"
            >
              <Link to="/About">Read More</Link>
              <Link to="/Become">Become a Citizen</Link>
            </div>
          </div>
        </div>
      </div>

      <section className="sec-extra">
        <div className="meanbox">
          <div className="ourbox ">
            <h2>The Mission</h2>
            <p>To make a 2500 year old dream come true!</p>
          </div>
          <div className="ourbox ">
            <h2>The Challenge</h2>
            <p>To unite the world for a common cause!</p>
          </div>
          <div className="ourbox ">
            <h2>The Adventure</h2>
            <p>The opportunity to create your own World History!</p>
          </div>
          <div className="ourbox ">
            <h2>The Reward</h2>
            <p>
              Become a Cyber Nation citizen ship member! Great memories for you{" "}
              <span>to keep and share!</span>{" "}
            </p>
          </div>
          <div className="ourbox ">
            <h2>The Mission</h2>
            <p>
              Sign up Introductory offer makes you a foundation member{" "}
              <span>
                This opportunity is on offer to every human on planet Earth
              </span>
            </p>
          </div>
        </div>
      </section>

      <section className="sec-1 hm">
        <span className="sp-ban-1">
          {" "}
          <img src={bannerleftsideimg} />
        </span>
        <span className="sp-ban-2">
          {" "}
          <img src={bannerrightsideimg} />
        </span>
        <div className="container-1340">
          <div className="row">
            <div className="col-lg-1"> </div>
            <div
              className="col-lg-5 about-img"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src={sec1homeimg} />
            </div>
            <div className="col-lg-6">
              <span className="ALex">
                {" "}
                <img
                  src={Alex}
                  data-aos="zoom-in"
                  data-aos-duration="2500"
                  data-aos-offset="200"
                />
              </span>
              <div
                className="aobut-content"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h2>
                  About <br /> <span>Alexander The Great</span>{" "}
                </h2>
                <p>
                  Alexander III ancient King of Macedoonia, had a grand
                  ideological purpose once he conquered Persia… to bring about
                  the “UNITY OF MANKIND” With today’s modern technology….
                  together in 2024 we can make Alexander’s dream come true, by
                  becoming a cyber citizen of the world’s first Cyber Nation…
                  NEW WORLD MACEDONIA.
                </p>
                <div class="banner-btn">
                  <Link to="/About">Read More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-1">
        <span className="sp-ban-1">
          {" "}
          <img src={bannerleftsideimg} />
        </span>
        <span className="sp-ban-2">
          {" "}
          <img src={bannerrightsideimg} />
        </span>
        <div className="container-1340">
          <div className="row">
            <div className="col-lg-1"> </div>
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
            >
              <span className="ALex">
                {" "}
                <img
                  src={Alex}
                  data-aos="zoom-in"
                  data-aos-duration="2500"
                  data-aos-offset="200"
                />
              </span>
              <div className="aobut-content ab">
                <h2>
                  About <br /> <span>Macedonia</span>{" "}
                </h2>
                <p>
                  Ancient Macedonia is a geographical area known as the Kingdom
                  of Macedon and dates back to at least 700 years BC and peaked
                  around 300 BC under Philip ll and his son Alexander lll who
                  conquered half the then known world. Included in his conquests
                  were the ancient Persian Empire, Egypt, parts of India and
                  several ancient Greek city states. <br />
                  Modern Macedonia with its capital Skopje, has been diminished
                  to a small nation now known as North Macedonia and is nestled
                  between Serbia, Bulgaria, Greece and Albania
                </p>
                <div class="banner-btn">
                  <Link to="/About">Read More</Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 aboutMacedonia"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src={maced} />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-2 bp-bt">
        <h1
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          How to
          <br /> <span>Become a Citizen</span>
        </h1>
        <span className="sp-ban-1">
          {" "}
          <img src={bannerleftsideimg} />
        </span>
        <span className="sp-ban-2">
          {" "}
          <img src={bannerrightsideimg} />
        </span>
        <div className="container-1050">
          <div className="row">
            <div
              className="col-lg-5 pb-5 become3"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src={how1} />
            </div>
            <div className="col-lg-7 pb-5">
              <span className="ALex">
                {" "}
                <img
                  src={Alex}
                  data-aos="zoom-in"
                  data-aos-duration="2500"
                  data-aos-offset="200"
                />
              </span>
              <div
                className="howto-content"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2>Register on website</h2>
                <p>
                  Citizenship to{" "}
                  <span style={{ color: "white", fontWeight: "600" }}>
                    {" "}
                    New World Macedonia{" "}
                  </span>
                  is open equally to all humans on planet Earth and will not be
                  restrictive in any form against any applicant ir-respective of
                  nationality, age, race, colour, gender identity, religious
                  beliefs. We have no borders, no nationalities, no politicians,
                  no passports, no wars and just want the world to peacefully
                  co-exist.
                </p>
              </div>
            </div>

            <div className="col-lg-7 pb-5 pb-5">
              <span className="ALex">
                {" "}
                <img
                  src={Alex}
                  data-aos="zoom-in"
                  data-aos-duration="2500"
                  data-aos-offset="200"
                />
              </span>
              <div
                className="howto-content"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2>Fill Citizenship Form</h2>
                <p>
                  Click on the Fill Citizenship Form Button Provide your details
                  follow the prompts and make your payment to receive your very
                  own world history First Cyber Nation citizenship certificate
                  from{" "}
                  <span style={{ color: "white", fontWeight: "600" }}>
                    {" "}
                    New World Macedonia .
                  </span>
                </p>
                <div
                  class="banner-btn"
                  style={{ justifyContent: "start", marginTop: "30px" }}
                >
                  <Link to="/Become">Become a Citizen</Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 pb-5 become3"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src={how2} />
            </div>

            <div
              className="col-lg-5 become3"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src={how3} />
            </div>
            <div
              className="col-lg-7 "
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <span className="ALex">
                {" "}
                <img
                  src={Alex}
                  data-aos="zoom-in"
                  data-aos-duration="2500"
                  data-aos-offset="200"
                />
              </span>
              <div className="howto-content">
                <h2>Get Certificate</h2>
                <p>
                  Introductory{" "}
                  <span style={{ color: "white", fontWeight: "600" }}>
                    {" "}
                    New World Macedonia{" "}
                  </span>{" "}
                  Citizenship Certificates issued until 31st July 2024 will be
                  treated as foundation members of the world’s first Cyber
                  Nation and may qualify as recognition in an attempt for a
                  world record recognition from the Guinness Book of World
                  Records.{" "}
                </p>
                <div
                  class="banner-btn"
                  style={{ justifyContent: "start", marginTop: "30px" }}
                >
                  <Link to="/Become">Become a Citizen</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Index;
