import { Route, Routes } from "react-router-dom";
import "../src/assets/css/Dashboard.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import Index from "./Pages/Home/Index";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Contact from "./Pages/Home/Contact";
import Become from "./Pages/Home/Become";
import Login from "./Pages/Home/Login";
import Forget from "./Pages/Home/Forget";
import Verification from "./Pages/Home/Verification";
import NewPassword from "./Pages/Home/NewPassword";
import Register from "./Pages/Home/Register";
import About from "./Pages/Home/About";
import Dashboard from "./CitizenDashboard/Dashboard";
import Certificate from "./CitizenDashboard/Certificate";
import Notifications from "./CitizenDashboard/Notifications";
import BankDetail from "./CitizenDashboard/BankDetail";
import AdminDashboard from "./assets/Admin/AdminDashboard";
import CitizenAdmin from "./assets/Admin/CitizenAdmin";
import PublishNotice from "./assets/Admin/PublishNotice";
import CertificateAdmin from "./assets/Admin/CertificateAdmin";
import DashboardEdit from "./CitizenDashboard/DashboardEdit";
import Term from "./Pages/Home/Term";
import PrivacyPolicy from "./Pages/Home/PrivacyPolicy";
import CookiePolicy from "./Pages/Home/CookiePolicy";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./assets/Component/Layout";

function App() {
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dashboard-edit" element={<DashboardEdit />} />
          <Route path="/become" element={<Become />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/verification/" element={<Verification />} />
          <Route path="/newPassword/" element={<NewPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/term" element={<Term />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/about" element={<About />} />
          <Route path="/dashboard/" element={<Dashboard />} />
          <Route path="/certificate" element={<Certificate />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/bankDetail" element={<BankDetail />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/citizens" element={<CitizenAdmin />} />
          <Route path="/PublishNotice" element={<PublishNotice />} />
          <Route path="/CertificateAdmin" element={<CertificateAdmin />} />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
