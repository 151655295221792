import React from "react";
import Logo from "../../assets/images/Logo.png";
import profile from "../../assets/images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";

function Sidebar(props) {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div>
      <div className="Dashboard-left-bar">
        <div className="Dash-logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="profile-upload">
          <label htmlFor="photo-upload" className="custom-file-upload fas">
            <div className="img-wrap img-upload">
              <img
                src={props.imagePath || profile}
                alt="Uploaded"
                className="rounded-avatar"
              />
            </div>
            <span>
              <FontAwesomeIcon icon={faCamera} beatFade />
            </span>
            <input
              id="photo-upload"
              type="file"
              accept="image/*"
              onChange={props.handleImageChange}
            />
          </label>
          <p>
            {props.userFName} {props.userLName}
          </p>
        </div>
        <div className="ul-btn">
          <div className="Dash-menus">
            <ul>
              <li className={splitLocation[1] === "Dashboard" ? "active" : ""}>
                {" "}
                <Link to="/Dashboard">Edit Profile</Link>{" "}
              </li>
              <li
                className={
                  splitLocation[1] === "Certificate" ||
                  splitLocation[1] === "BankDetail"
                    ? "active"
                    : ""
                }
              >
                {" "}
                <Link to={props.isPayment ? "/Certificate" : "/BankDetail"}>
                  Certificate
                </Link>{" "}
              </li>
              <li
                className={splitLocation[1] === "Notifications" ? "active" : ""}
              >
                {" "}
                <Link to="/Notifications">Notifications</Link>{" "}
              </li>
            </ul>
          </div>

          <div class="banner-btn">
            <Link>Logout</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
