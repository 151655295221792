import React from "react";
import Logo from "../assets/images/Logo.png";
import { Link } from "react-router-dom";
import Sidebar from "../assets/Component/Sidebar";

function Notifications() {
  return (
    <div>
      <section className="Dashboard-citezen">
        <Sidebar />

        <div className="Dashboard-righ-side">
          <div className="dash-title">
            <h1>Get Your Citizenship Certificate</h1>
          </div>

          <div className="dash-page-con notificationpg">
            <div className="enter-form">
              <div className="row notificationpg">
                <div className="col-lg-1">
                  <img src={Logo} />
                </div>
                <div className="col-lg-11">
                  <h2>Data</h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolor non nihil aut atque sint, a quod cum consequatur
                    recusandae at est, voluptatibus tempore ratione obcaecati
                    provident enim doloribus nesciunt praesentium!
                  </p>
                  <span>12 hours ago.</span>

                  <span className="red-dots"></span>
                </div>
              </div>
              <div class="banner-btn">
                <Link>Refresh</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Notifications;
