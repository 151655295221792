import React from "react";
import backbtn from "../images/backbtn.png";
import cert from "../images/certi-image.jpg";

import SidebarAdmin from "../Component/SidebarAdmin";
import { Link } from "react-router-dom";

function CertificateAdmin() {
  return (
    <div>
      <section className="Dashboard-citezen">
        <SidebarAdmin />

        <div className="Dashboard-righ-side">
          <div className="dash-title">
            <h1>Get Your Citizenship Certificate</h1>
          </div>

          <div className="dash-page-con certificate-page">
            <div className="certificate-sec">
              <div className="back-btn">
                <Link to="/citizens">
                  {" "}
                  <img src={backbtn} />
                </Link>

                <div className="download-pdf"></div>
              </div>
              <div className="certicate-editable">
                <span className="certnme">Mark Allen</span>
                <span className="cetdate">12-1-2024</span>
                <img src={cert} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CertificateAdmin;
