import React from "react";
import logo from "../../assets/images/Logo.png";
import { Link, useLocation } from "react-router-dom";
import GoogleTranslate from "./GoogleTranslate";

function Header() {
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <div>
      <header>
        <div className="head-menus">
          <ul>
            <li className={splitLocation[1] === "" ? "active" : ""}>
              <Link to="/"> Home</Link>
            </li>
            <li className={splitLocation[1] === "About" ? "active" : ""}>
              <Link to="/About"> About Us</Link>
            </li>
          </ul>
          <ul className="child-ul-2">
            <li>
              <Link>
                <img src={logo} />
              </Link>
            </li>
          </ul>
          <ul className="child-ul-3">
            <li className={splitLocation[1] === "Contact" ? "active" : ""}>
              <Link to="/Contact"> Contact Us</Link>
            </li>
            <li className={splitLocation[1] === "Become" ? "active" : ""}>
              <Link to="/Become"> Become a Citizen</Link>
            </li>
            <li>
              <Link to="/Login"> Login</Link>
            </li>
          </ul>
        </div>

        <GoogleTranslate />
      </header>
    </div>
  );
}

export default Header;
