import React from "react";
import videoban from "../../assets/video/HeroBanner.mp4";

import { Link } from "react-router-dom";
import bannerleftsideimg from "../../assets/images/banner-left-side-img.png";
import bannerrightsideimg from "../../assets/images/banner-right-side-img.png";
import emaillogin from "../../assets/images/emaillogin.svg";
import Logo from "../../assets/images/Logo.png";
import backtoweb from "../../assets/images/backtoweb.svg";

function Forget() {
  return (
    <div className="login-pg forget">
      <div className="bg-video-wrap">
        <video src={videoban} loop muted autoPlay></video>
        <div className="banner-content">
          <span className="backtoweb">
            {" "}
            <Link to="/">
              {" "}
              <img src={backtoweb} />
            </Link>
          </span>
          <span className="sp-ban-1">
            {" "}
            <img src={bannerleftsideimg} />
          </span>
          <span className="sp-ban-2">
            {" "}
            <img src={bannerrightsideimg} />
          </span>
          <div className="banner-login">
            <span className="bg-login-logo">
              <img src={Logo} alt="Logo" />
            </span>
            <div className="row">
              <div className="col-lg-12">
                <h1>Forgot Password</h1>

                <p className="welcom">Enter your email for a recovery code</p>
                <form className="input-login">
                  <div className="field-in">
                    <input type="email" id="" placeholder="Email Address" />
                    <img src={emaillogin} alt="Email Icon" />
                  </div>

                  <div className="banner-btn">
                    <Link>Send</Link>
                  </div>
                  <div className="forgerting">
                    <span>
                      <p>Have an account?</p>
                      <Link to="/Login">
                        {" "}
                        <p>Log In</p>
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forget;
