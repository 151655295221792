import "aos/dist/aos.css";
import React from "react";
// import { Link } from 'react-router-dom';
import videoban from "../../assets/video/HeroBanner.mp4";
import logo from "../../assets/images/Logo.png";
import { Link } from "react-router-dom";
import bannerleftsideimg from "../../assets/images/banner-left-side-img.png";
import bannerrightsideimg from "../../assets/images/banner-right-side-img.png";
import worldban from "../../assets/images/world-ban.png";

import location from "../../assets/images/location.png";
import email from "../../assets/images/email.png";

import Footer from "../../assets/Component/Footer";
import Header from "../../assets/Component/Header";

function Contact() {
  return (
    <div>
      <Header />

      <div className="bg-video-wrap">
        <video src={videoban} loop muted autoPlay></video>
        <div className="banner-content">
          <span className="sp-ban-1">
            {" "}
            <img src={bannerleftsideimg} />
          </span>
          <span className="sp-ban-2">
            {" "}
            <img src={bannerrightsideimg} />
          </span>
          <div className="banner-title">
            <div className="banner-contact">
              <span className="sp-ban-world">
                {" "}
                <img src={worldban} />
              </span>
              <h1 data-aos="zoom-in" data-aos-duration="2000">
                Contact Us
              </h1>
              <h2 data-aos="zoom-in" data-aos-duration="2000">
                New World Macedonia
              </h2>
              <p data-aos="zoom-in" data-aos-duration="2000">
                The world's first Cyber Nation
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="sec-1-contact">
        <span className="sp-ban-1">
          {" "}
          <img src={bannerleftsideimg} />
        </span>
        <span className="sp-ban-2">
          {" "}
          <img src={bannerrightsideimg} />
        </span>
        <div className="sec-contact-title">
          <h1>Get in touch with us.</h1>
          <h2>Send us a message.</h2>
        </div>

        <div className="row">
          <div className="col-lg-8">
            <form>
              <label htmlFor="name">Name</label>
              <input type="text" id="name" />

              <label htmlFor="email">Email</label>
              <input type="email" id="email" />
              <label htmlFor="msg">Message</label>
              <textarea name="" id="msg" cols="30" rows="10"></textarea>

              <div className="form-btm">
                <Link>Submit</Link>
              </div>
            </form>
          </div>
          <div className="col-lg-4 social-icons m-auto">
            <img src={logo} />
            <h2>Get in touch</h2>

            <span className="links-social">
              {" "}
              <Link to="mailto:info@newworldmacedonia.com.au">
                {" "}
                <img src={email} /> info@newworldmacedonia.com.au{" "}
              </Link>
            </span>
            <span className="links-social">
              {" "}
              <Link
                to="https://www.google.com/maps/place/Coronet+Bay+VIC+3984,+Australia/@-38.4347828,145.4329465,15z/data=!3m1!4b1!4m6!3m5!1s0x6ad5f830318a87d1:0x40579a430a06dc0!8m2!3d-38.4346491!4d145.4410083!16s%2Fm%2F0r8q5rd?entry=ttu"
                target="blank"
              >
                {" "}
                <img src={location} /> Cornonet Bay, VIC, 3984{" "}
              </Link>
            </span>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Contact;
