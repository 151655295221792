import React, { useState } from "react";

import videoban from "../../assets/video/HeroBanner.mp4";
import logo from "../../assets/images/Logo.png";
import { Link } from "react-router-dom";
import bannerleftsideimg from "../../assets/images/banner-left-side-img.png";
import bannerrightsideimg from "../../assets/images/banner-right-side-img.png";
import backtoweb from "../../assets/images/backtoweb.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  return (
    <div className="login-pg register-pg">
      <div className="bg-video-wrap">
        <video src={videoban} loop muted autoPlay></video>
        <div className="banner-content">
          <span className="sp-ban-1">
            {" "}
            <img src={bannerleftsideimg} />
          </span>
          <span className="sp-ban-2">
            {" "}
            <img src={bannerrightsideimg} />
          </span>
          <section className="sec-1-contact">
            <div className="row">
              <div className="sec-contact-title">
                <h1>Register as a Citizen</h1>
                <h2>Of New World Macedonia</h2>
              </div>
              <div className="col-lg-8">
                <div className="row under-row">
                  <div className="col-lg-6">
                    <label htmlFor="">First Name</label>
                    <input type="text" required />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="">Last Name</label>
                    <input type="text" required />
                  </div>
                  <div className="col-lg-12">
                    <label htmlFor="">Email</label>
                    <input type="email" />
                  </div>
                  <div className="col-lg-12">
                    <label htmlFor="">Phone</label>
                    <input type="number" min="1" max="100" required />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="">Enter Password</label>

                    <div className="field-in">
                      <input
                        type={showPassword ? "text" : "password"}
                        id=""
                        required
                      />

                      <div className="eye-hide">
                        {showPassword ? (
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={togglePasswordVisibility}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={togglePasswordVisibility}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="">Confirm Password</label>
                    {/* <input
                      type="password"
                      value={cPass}
                      onChange={(e) => setCPass(e.target.value)}
                    /> */}

                    <div className="field-in">
                      <input
                        type={showPassword1 ? "text" : "password"}
                        id=""
                        required
                      />

                      <div className="eye-hide">
                        {showPassword1 ? (
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={togglePasswordVisibility1}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={togglePasswordVisibility1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="forgerting">
                    <span>
                      <input type="checkbox" id="vehicle1" />
                      <p>
                        I agree on{" "}
                        <Link to="/term">
                          {" "}
                          <u>terms and conditions</u>
                        </Link>
                      </p>
                    </span>
                  </div>
                  <div class="banner-btn">
                    <Link>Submit</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4  regiter-log">
                <img src={logo} />
                <h2>
                  Citizenship of <br />
                  New World Macedonia
                </h2>
                <p>The world's first Cyber Nation</p>

                <span class="backtoweb">
                  {" "}
                  <Link to="/">
                    {" "}
                    <img src={backtoweb} />
                  </Link>
                </span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Register;
