import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../assets/Component/Sidebar";
import pfinput from "../assets/images/pf-input.svg";

function DashboardEdit() {
  return (
    <div>
      <section className="Dashboard-citezen">
        <Sidebar />

        <div className="Dashboard-righ-side">
          <div className="dash-title">
            <h1>Get Your Citizenship Certificate</h1>
          </div>

          <div className="dash-page-con">
            <div className="enter-form">
              <div className="row get-cert">
                <div className="col-lg-3 ">
                  <label>Upload Profile Picture</label>

                  <label
                    htmlFor="photo-upload2"
                    className="custom-file-upload fas"
                  >
                    <div className="img-wrap img-upload">
                      <img
                        src={pfinput}
                        alt="Uploaded"
                        className="rounded-avatar"
                      />
                    </div>

                    <input id="photo-upload2" type="file" accept="image/*" />
                    <p>Upload Picture</p>
                  </label>
                </div>
                <div className="col-lg-9 m-auto">
                  <h2>Get your citizenship certificate for $10.00</h2>
                </div>
                <div className="col-lg-6">
                  <label>First Name</label>
                  <input type="text" id="" placeholder="Mark" />
                </div>
                <div className="col-lg-6">
                  <label> Last Name</label>
                  <input type="text" id="" placeholder="Allen" />
                </div>
                <div className="col-lg-6">
                  <label>Email</label>
                  <input
                    type="email"
                    id=""
                    placeholder="mark_allen@gmail.com"
                  />
                </div>
                <div className="col-lg-6">
                  <label>Phone Number</label>
                  <input type="number" id="" placeholder="1234 567 890" />
                </div>
                <div className="col-lg-12">
                  <label>Address</label>

                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Lorem ipsum dolor sir contour lorem ipsum dolor sir contour"
                  ></textarea>
                </div>
                <div className="col-lg-6">
                  <label>City</label>
                  <input type="text" id="" placeholder="Melbourne" />
                </div>
                <div className="col-lg-6">
                  <label>State</label>
                  <input type="text" id="" placeholder="New South Wales" />
                </div>
                <div className="col-lg-6">
                  <label>Country</label>
                  <input type="text" id="" placeholder="Australia" />
                </div>
                <div class="banner-btn">
                  <Link to="/BankDetail">Next</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardEdit;
