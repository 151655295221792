import "aos/dist/aos.css";
import videoban from "../../assets/video/HeroBanner.mp4";
import bannerleftsideimg from "../../assets/images/banner-left-side-img.png";
import bannerrightsideimg from "../../assets/images/banner-right-side-img.png";
import worldban from "../../assets/images/world-ban.png";
import Footer from "../../assets/Component/Footer";
import Header from "../../assets/Component/Header";

// importing aos

function Term() {
  return (
    <div className="policiespages">
      <Header />

      <div className="bg-video-wrap">
        <video src={videoban} loop muted autoPlay></video>
        <div className="banner-content">
          <span className="sp-ban-1">
            {" "}
            <img src={bannerleftsideimg} />
          </span>
          <span className="sp-ban-2">
            {" "}
            <img src={bannerrightsideimg} />
          </span>
          <div className="banner-title">
            <div className="banner-contact">
              <i class="fa-solid fa-download fa-spin fa-spin-reverse">
                {" "}
                <span className="sp-ban-world">
                  {" "}
                  <img src={worldban} />
                </span>
              </i>
              <h1 data-aos="zoom-in" data-aos-duration="2000">
                Terms & Conditions{" "}
              </h1>
              <h2 data-aos="zoom-in" data-aos-duration="2000">
                New World Macedonia
              </h2>
              <p data-aos="zoom-in" data-aos-duration="2000">
                The world's first Cyber Nation
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="sec-1 termscon ">
        <span className="sp-ban-1">
          {" "}
          <img src={bannerleftsideimg} />
        </span>
        <span className="sp-ban-2">
          {" "}
          <img src={bannerrightsideimg} />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="aobut-content"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h2>Terms & Conditions </h2>
                <p>
                  On payment and receiving your New World Macedonia Cyber
                  Citizenship certificate you agree to and are bound by the
                  following terms and conditions.
                  <br />
                  1. Your New World Macedonia Cyber Citizenship certificate is
                  not transferable.
                  <br />
                  2. Once payments received and Certificate issued there are no
                  refunds available.
                  <br />
                  3. Participation before 31st July 2024 entitles you to be
                  counted and included as a foundation member of New World
                  Macedonia Cyber Nation.
                  <br />
                  4. Participants are not authorized and agree to not copy and
                  misuse any material and intellectual property belonging to New
                  World Macedonia
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Term;
