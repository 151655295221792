import * as React from "react";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";

const SquarePaymentForm = (props) => (
  <PaymentForm
    /**
     * Identifies the calling form with a verified application ID generated from
     * the Square Application Dashboard.
     */
    applicationId="sq0idp-lRFO1kTBGZoFjrB0twXLHw"
    /**
     * Invoked when payment form receives the result of a tokenize generation
     * request. The result will be a valid credit card or wallet token, or an error.
     */
    cardTokenizeResponseReceived={async (token, buyer) => {
      console.info({ token, buyer });
      let body = JSON.stringify({
        sourceId: token.token,
      });
      const paymentResponse = await fetch("/checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });

      console.log(paymentResponse);

      if (paymentResponse.ok) {
        await props.handleSubmit();
        return paymentResponse.json();
      }
    }}
    /**
     * This function enable the Strong Customer Authentication (SCA) flow
     *
     * We strongly recommend use this function to verify the buyer and reduce
     * the chance of fraudulent transactions.
     */

    /**
     * Identifies the location of the merchant that is taking the payment.
     * Obtained from the Square Application Dashboard - Locations tab.
     */
    locationId="LPB6ZTB00RZB0"
  >
    <CreditCard />
  </PaymentForm>
);

export default SquarePaymentForm;
