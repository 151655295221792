import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import videoban from "../../assets/video/HeroBanner.mp4";
import bannerleftsideimg from "../../assets/images/banner-left-side-img.png";
import bannerrightsideimg from "../../assets/images/banner-right-side-img.png";

import worldban from "../../assets/images/world-ban.png";
import Footer from "../../assets/Component/Footer";
import Header from "../../assets/Component/Header";

// importing aos

function CookiePolicy() {
  const [showMore, setShowMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleContent = () => {
    setShowMore(!showMore);
  };
  useEffect(() => {
    AOS.init({
      // AOS configuration options
    });

    // Refresh AOS when component mounts
    AOS.refresh();

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Refresh AOS on scroll to trigger animations
    AOS.refresh();
  };

  return (
    <div className="policiespages">
      <Header />

      <div className="bg-video-wrap">
        <video src={videoban} loop muted autoPlay></video>
        <div className="banner-content">
          <span className="sp-ban-1">
            {" "}
            <img src={bannerleftsideimg} />
          </span>
          <span className="sp-ban-2">
            {" "}
            <img src={bannerrightsideimg} />
          </span>
          <div className="banner-title">
            <div className="banner-contact">
              <i class="fa-solid fa-download fa-spin fa-spin-reverse">
                {" "}
                <span className="sp-ban-world">
                  {" "}
                  <img
                    src={worldban}
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  />
                </span>
              </i>
              <h1 data-aos="zoom-in" data-aos-duration="2000">
                Cookie Policy{" "}
              </h1>
              <h2 data-aos="zoom-in" data-aos-duration="2000">
                New World Macedonia
              </h2>
              <p data-aos="zoom-in" data-aos-duration="2000">
                The world's first Cyber Nation
              </p>

              {/* <div className="banner-btn">
              <Link>Read More</Link>
              <Link>Become a Citizen</Link>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      <section className="sec-1 ">
        <span className="sp-ban-1">
          {" "}
          <img src={bannerleftsideimg} />
        </span>
        <span className="sp-ban-2">
          {" "}
          <img src={bannerrightsideimg} />
        </span>
        <div className="container-1340">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="aobut-content"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h2>Cookie Policy</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                  in culpa qui officia deserunt mollit anim id est laborum.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default CookiePolicy;
