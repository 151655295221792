import React from "react";
import Sidebar from "../assets/Component/Sidebar";
import SquarePaymentForm from "./SquarePayment";

function BankDetail() {
  return (
    <div>
      <section className="Dashboard-citezen">
        <Sidebar />

        <div className="Dashboard-righ-side">
          <div className="dash-title forcerti">
            <h1>
              Get Your Citizenship Certificate <br />{" "}
              <i class=" fa-fade">
                {" "}
                <span>For $25</span>
              </i>
            </h1>
          </div>

          <div className="dash-page-con">
            <div className="enter-form">
              <h2>Enter Bank Details</h2>

              <SquarePaymentForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BankDetail;
