import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../assets/Component/Sidebar";
import pfinput from "../assets/images/pf-input.svg";
function Dashboard() {
  return (
    <>
      <div>
        <section className="Dashboard-citezen">
          <Sidebar />

          <div className="Dashboard-righ-side">
            <div className="dash-title">
              <h1>Get Your Citizenship Certificate</h1>
            </div>

            <div className="dash-page-con">
              <div className="enter-form">
                <div className="row get-cert">
                  <div className="col-lg-3 ">
                    <label>Upload Profile Picture</label>

                    <label
                      htmlFor="photo-upload2"
                      className="custom-file-upload fas"
                    >
                      <div className="img-wrap img-upload">
                        <img
                          src={pfinput}
                          alt="Uploaded"
                          className="rounded-avatar"
                        />
                      </div>

                      <input
                        id="photo-upload2"
                        type="file"
                        accept=".jpg .jpeg .png"
                      />
                      <p>Upload Picture</p>
                    </label>
                  </div>
                  <div className="col-lg-9 m-auto"></div>
                  <div className="col-lg-6">
                    <label>First Name</label>
                    <input type="text" id="" placeholder="Mark" />
                  </div>
                  <div className="col-lg-6">
                    <label> Last Name</label>
                    <input type="text" id="" placeholder="Allen" />
                  </div>
                  <div className="col-lg-6">
                    <label>Email</label>
                    <input
                      type="email"
                      id=""
                      placeholder="mark_allen@gmail.com"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>Phone Number</label>
                    <input type="number" id="" placeholder="1234 567 890" />
                  </div>
                  <div className="col-lg-12">
                    <label>Address</label>

                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      placeholder="Address"
                    ></textarea>
                  </div>
                  <div className="col-lg-6">
                    <label>Country</label>
                    <select>
                      <option value="none" selected disabled></option>
                      <option>Ukrain</option>
                      <option>America</option>
                      <option>England</option>
                      <option>Australia</option>
                      <option>New Zealand</option>
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <label>State</label>
                    <select>
                      <option value="none"></option>
                      <option>Ukrain</option>
                      <option>America</option>
                      <option>England</option>
                      <option>Australia</option>
                      <option>New Zealand</option>
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <label>City</label>
                    <select>
                      <option value="none"></option>
                      <option>Karchi</option>
                      <option>Lahore</option>
                      <option>Islamabad</option>
                      <option>Firdus</option>
                    </select>
                  </div>

                  <div class="banner-btn">
                    <Link>Update</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Dashboard;
